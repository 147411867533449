import React from "react";
import { List } from "../../../interfaces/list";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import open_letter from "../assets/open_letter.png";
import gift from "../assets/gift.png";
import checklist from "../assets/checklist.png";
import santa_with_sack_full from "../assets/santa_with_sack_full.png";
import santa_face_40 from "../assets/santa_face_40.png";
import CreateListModal from "./CreateListModal";

interface Props {
  setList: React.Dispatch<React.SetStateAction<List>>;
  message: string;
}

const HomePage = (props: Props) => {
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Container style={{ paddingTop: "3em", marginBottom: "20px" }}>
        <Row>
          <Col>
            <h1 className="explain-header">Secret Santa</h1>
            <p>Organise your secret santa gift exchange in minutes!</p>
            <p>
              <CreateListModal setList={props.setList} />
            </p>
          </Col>
          <Col>
            <div className="text-center">
              <img src={santa_with_sack_full} alt="Santa with a green sack" className="image-santa" />
            </div>
          </Col>
        </Row>
      </Container>
      <Container style={{ marginBottom: "70px", textAlign: "center" }}>
        <Row>
          <Col>
            <img height="140px" width="140px" src={checklist} alt="" />
            <h4 className="explain-header">Easy organising</h4>
            <p>
              If you're organising a gift exchange for your family or workplace, let Secret Santa take care of the
              organising for you. Easily add lists of your contacts, send out notification emails, and shuffle your list
              of santas.
            </p>
          </Col>
          <Col>
            <img height="140px" width="140px" src={open_letter} alt="" />
            <h4 className="explain-header">How it works</h4>
            <p>
              Once you have your list of Santas, let the system take care of the rest! It will shuffle all your users
              around, no matter how big your list is, and randomly assign each person a Santa. Once everyone is
              assigned, each Santa receives a notification email letting them know what the details are.
            </p>
          </Col>
          <Col>
            <img height="140px" width="140px" src={gift} alt="" />
            <h4 className="explain-header">It's free!</h4>
            <p>
              Secret Santa is free to use. There's no adverts, no spamming and we keep your details safe. Secret Santa
              is completely free to use! How is this possible?
              <a href="https://jeanklaas.com/contact"> Contact me</a> if you'd like to find out. Or you can read{" "}
              <a href="https://jeanklaas.com/blog/rails-to-serverless-a-christmas-story/">this blog post.</a>
            </p>
          </Col>
        </Row>
        <Row>
          <Container fluid style={{ textAlign: "center" }}>
            <Button variant="primary" onClick={handleShow}>
              Watch video and learn more
            </Button>
            <Modal id="how-it-works" title="How it works" ok-only="true" show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>How it works</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="auto-resizable-iframe">
                  <div>
                    <iframe
                      width="560"
                      title="Learn about how secret santa works"
                      height="315"
                      src="https://www.youtube.com/embed/BCUq2gmUDpw"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </Container>
        </Row>
      </Container>
      <footer className="footer">
        <div className="container text-center">
          <p id="footerMsg">
            <img src={santa_face_40} alt="" />
            Made for fun by{" "}
            <a id="footerLink" href="http://twitter.com/jgunnink/">
              JK Gunnink
            </a>
            .
          </p>
        </div>
      </footer>
    </>
  );
};

export default HomePage;
