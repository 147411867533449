import React from "react";
import "./App.css";
import { List } from "./types";
import HomePage from "./components/HomePage";
import ListEditPage from "./components/ListEditPage";

const App = () => {
  const [list, setList] = React.useState<List>({
    name: "",
    value: 0,
    giftDay: "",
    organiserName: "",
    santas: [],
  });
  const [message, setMessage] = React.useState("");

  if (!list.organiserName || !list.name)
    return <HomePage setList={setList} message={message} />;
  else
    return (
      <ListEditPage
        list={list}
        setList={setList}
        message={message}
        setMessage={setMessage}
      />
    );
};

export default App;
