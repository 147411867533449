import { Santa } from "../types";

export const duplicateEmailsFound = (santas: Santa[]): boolean => {
  const emails: String[] = [];
  santas.forEach(function (santa) {
    emails.push(santa.email);
  });
  return new Set(emails).size !== emails.length;
};

export const allEmailsAreValid = (santas: Santa[]): boolean => {
  // Taken from https://emailregex.com/
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let returnValue = true;
  santas.forEach(function (santa) {
    if (santa.email.match(emailRegex) === null) {
      returnValue = false;
    }
  });
  return returnValue;
};

export const filterEmptyRecords = (santas: Santa[]) => {
  return santas.filter((santa) => santa.name !== "" && santa.email !== "");
};
