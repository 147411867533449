import * as React from "react";
import { Button, Modal, Form } from "react-bootstrap";

interface Props {
  setList: Function;
}

const CreateListModal = (props: Props) => {
  const [show, setShow] = React.useState(false);
  const [listName, setListName] = React.useState("");
  const [organiserName, setOrganiserName] = React.useState("");

  const handleOk = () => {
    props.setList({ name: listName, organiserName: organiserName });
    setShow(false);
  };
  const handleCancel = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Get Started
      </Button>

      <Modal show={show} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>List information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicInput1">
              <Form.Label>Your name</Form.Label>
              <Form.Control type="text" placeholder="Your Name" onChange={(e) => setOrganiserName(e.target.value)} />
              <Form.Text className="text-muted">
                Enter your name so your participants know who sent them the message.
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="formBasicInput2">
              <Form.Label>List name</Form.Label>
              <Form.Control type="text" placeholder="List Name" onChange={(e) => setListName(e.target.value)} />
              <Form.Text className="text-muted">
                Give the list a name, for example: Office Secret Santa - 2018.
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleOk}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateListModal;
