import * as React from "react";
import { List, Santa } from "../../../interfaces/list";
import { Container, Button, Alert, Form } from "react-bootstrap";
import {
  duplicateEmailsFound,
  allEmailsAreValid,
  filterEmptyRecords,
} from "../helpers/validations";
import { sendSantas } from "../actions/list";

interface Props {
  list: List;
  setList: React.Dispatch<React.SetStateAction<List>>;
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
}

const ListEditPage = (props: Props) => {
  const { list, setList, message, setMessage } = props;
  const [submitting, setSubmitting] = React.useState(false);
  const [unableToSubmit, setUnableToSubmit] = React.useState(false);
  const [failureReason, setFailureReason] = React.useState("");
  const [ready, setReady] = React.useState(false);
  const [complete, setComplete] = React.useState(false);
  const [santas, setSantas] = React.useState<Santa[]>([
    { name: "", email: "" },
  ]);

  React.useEffect(() => {
    if (ready) {
      const postRequest = async () => {
        const response = await sendSantas(list);
        if (response.status === 200) {
          setComplete(true);
        } else {
          setMessage("error");
        }
      };
      postRequest();
      setSubmitting(false);
      setReady(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready]);

  const addBlankSanta = (santas: Santa[]) => {
    santas.push({ name: "", email: "" });
    setSantas([...santas]);
  };

  const removeSanta = () => {
    let removedSantas = santas;
    santas.pop();
    if (removedSantas !== undefined) setSantas([...removedSantas]);
    return;
  };

  const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const onSubmit = async () => {
    setFailureReason(
      "You need at least three Santas in your list and valid gift details"
    );
    setSubmitting(true);
    if (requiredChecksFailed(santas)) {
      setUnableToSubmit(true);
      setSubmitting(false);
      await sleep(5000);
      setUnableToSubmit(false);
    } else if (!allEmailsAreValid(santas)) {
      setFailureReason(
        "There is an invalid value for an email address in the list"
      );
      setUnableToSubmit(true);
      setSubmitting(false);
      await sleep(5000);
      setUnableToSubmit(false);
    } else if (duplicateEmailsFound(santas)) {
      setFailureReason("The same email has appeared twice in the list.");
      setUnableToSubmit(true);
      setSubmitting(false);
      await sleep(5000);
      setUnableToSubmit(false);
    } else {
      const filteredList = filterEmptyRecords(santas);
      setList({ ...list, santas: filteredList });
      setReady(true);
    }
    setFailureReason("");
  };

  const requiredChecksFailed = (santas: Santa[]): boolean => {
    return (
      filterEmptyRecords(santas).length < 3 ||
      !list.organiserName ||
      !list.name ||
      !list.value ||
      !list.giftDay
    );
  };

  if (complete)
    return (
      <Container style={{ marginTop: "10px" }}>
        <Alert variant="success">
          <Alert.Heading>Done!</Alert.Heading>
          <p>List submitted, your Santas have been emailed!</p>
          <p>
            <a href="/">Do it again?</a>
          </p>
        </Alert>
      </Container>
    );
  else
    return (
      <Container style={{ marginBottom: "30px" }}>
        <h2>Setup your list!</h2>
        <p>{list.organiserName}, let's get started with some details:</p>
        <p>
          Fill in all the names and emails of those participating in {list.name}
          . Enter as many names as required.
        </p>

        <Form.Group>
          <Form.Label>Gift value amount: ($)</Form.Label>
          <Form.Text className="text-muted">Numbers only. Eg: 40</Form.Text>
          <Form.Control
            type="number"
            required
            placeholder="Enter gift value."
            onChange={(e) =>
              setList({ ...list, value: Number(e.target.value) })
            }
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Day to exchange gifts</Form.Label>
          <Form.Text className="text-muted">Numbers only. Eg: 40</Form.Text>
          <Form.Control
            type="date"
            required
            placeholder="Eg: 25th December"
            onChange={(e) => setList({ ...list, giftDay: e.target.value })}
          />
        </Form.Group>
        <Form inline>
          {santas.map((santa) => {
            return (
              <Form.Group
                key={santas.indexOf(santa)}
                style={{
                  marginRight: "20px",
                  display: "inline",
                }}
              >
                <p>Person {santas.indexOf(santa) + 1}</p>
                <Form.Control
                  required
                  placeholder="John Smith"
                  style={{ display: "inline" }}
                  onChange={(e) => (santa.name = e.target.value)}
                />
                <div style={{ paddingBottom: "5px" }} />
                <Form.Control
                  type="email"
                  required
                  placeholder="name@example.com"
                  style={{ display: "inline" }}
                  onChange={(e) => {
                    santa.email = e.target.value;
                  }}
                />
                <div style={{ marginBottom: "20px" }} />
              </Form.Group>
            );
          })}
        </Form>
        <Button
          variant="primary"
          disabled={submitting}
          onClick={() => addBlankSanta(santas)}
          style={{ marginTop: "10px" }}
        >
          Add Santa
        </Button>
        <Button
          variant="secondary"
          disabled={submitting || santas.length === 1}
          onClick={() => removeSanta()}
          style={{ marginTop: "10px", marginLeft: "10px" }}
        >
          Remove Santa
        </Button>
        <hr />
        <Alert variant="danger" show={message === "error"}>
          <Alert.Heading>Error!</Alert.Heading>
          <p>
            Something went wrong submitting your list. Please check all details
            and try again.
          </p>
          <p>
            If this keeps happening, something may be wrong with the service and
            you should probably tell me.
          </p>
        </Alert>
        <h5>Here are the details you've recorded for your list:</h5>
        <ul>
          <li>Your name: {list.organiserName}</li>
          <li>List name: {list.name}</li>
          <li>Gift value: {list.value}</li>
          <li>Gift day: {list.giftDay}</li>
          <li>
            You have {filterEmptyRecords(santas).length} non-empty entries in
            your list.
          </li>
        </ul>
        <Alert variant="warning" show={submitting}>
          Now shuffling and sending... Please wait
        </Alert>
        <Alert variant="warning" show={!submitting}>
          Please double check emails and names!
          <br />
          Incorrect emails means that people will miss out.
        </Alert>
        <Alert variant="danger" show={failureReason.length !== 0}>
          {failureReason}
        </Alert>
        <p>
          After you press the lock assign send button, each person will be
          emailed once, and there will be no further emails that get sent.{" "}
          <a href="https://jeanklaas.com/contact">Contact me</a> if you need any
          help.
        </p>
        <Button
          variant="primary"
          disabled={submitting || unableToSubmit}
          onClick={() => onSubmit()}
          style={{ marginTop: "10px" }}
        >
          Lock, Shuffle, Assign and Send!
        </Button>
      </Container>
    );
};

export default ListEditPage;
