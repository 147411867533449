import { List } from "../../../interfaces/list";

export const sendSantas = async (list: List) => {
  const response = await fetch("https://secretsanta.website/assign", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(list),
  });
  return response;
};
